export const fontColors = {
    foundation: 'text-content-foundation',
    quiet: 'text-content-quiet',
    accent: 'text-content-accent',
    'content-highlight': 'text-content-highlight',
    'layers-highlight': 'text-layers-highlight',
    shouty: 'text-content-shouty',
    white: 'text-neutrals-white',
    error: 'text-status-error-default',
    'error-dark': 'text-status-error-dark',
    info: 'text-status-info-default',
    warning: 'text-status-warning-default',
    success: 'text-status-success-default',
    price: 'text-products-price',
    saving: 'text-products-saving',
    'special-offer': 'text-promotion-specialOffer'
};

export const getFontColorClass = (color?: keyof typeof fontColors) => (color ? fontColors[color] : '');
